
import {
  defineComponent, computed, ref, onMounted, onUnmounted, Ref,
} from '@vue/composition-api';
import { FCButton } from '@fc/angie-ui';
import SplitView from '~/components/SplitView.vue';
import Video from '~/components/storyblok/Video/Video.vue';
import PdpSectionHeader from '~/components/v2/pdps/PdpSectionHeader.vue';
import FCImage from '~/components/FCImage.vue';
import AffirmPackagePrice from '~/components/v2/pdps/AffirmPackagePrice.vue';
import { useCartContext } from '~/composables/useCartContext';
import { useDrift } from '~/composables/useDrift';
import { isMobileEffect } from '~/effects/matchMedia';
import SelectItemCard from './SelectItemCard.vue';
import PackageStep from './packageBuilder/PackageStep.vue';
import SizingStep from './packageBuilder/SizingStep.vue';
import AffirmSection from './packageBuilder/AffirmSection.vue';

const media = {
  _uid: '54032ffd-3620-49c1-b9c6-e7b5c3414ecd',
  codeId: '',
  component: 'Video',
  isOverlay: false,
  playsAudio: false,
  hasControls: false,
  hasPlayIcon: false,
  mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/PDP HEADER.mp4',
  webmVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/BECOME+UNDEFEATABLE+WBSITE.webm',
  firstFrameImage: {
    id: 16685045,
    alt: 'FightCamp console and four trackers plugged into tv on table',
    name: '',
    focus: '',
    title: '',
    source: 'https://a.storyblok.com/f/152150/2434x2432/c2209286e3/1x1-bu-website.png',
    filename: 'https://a.storyblok.com/f/152150/2434x2432/c2209286e3/1x1-bu-website.png',
    copyright: '',
    fieldtype: 'asset',
    meta_data: {
      alt: 'FightCamp console and four trackers plugged into tv on table',
      title: '',
      source: '',
      copyright: '',
    },
    is_private: false,
    is_external_url: false,
  },
  mp4MobileVideoUrl: '',
  webmMobileVideoUrl: '',
  mobileVideoPlayback: 'auto',
  tabletUpVideoPlayback: 'auto',
  _editable: '<!--#storyblok#{"name": "Video", "space": "152150", "uid": "54032ffd-3620-49c1-b9c6-e7b5c3414ecd", "id": "530516862"}-->',
};

export default defineComponent({
  name: 'PackageBuilder',
  components: {
    SplitView,
    Video,
    FCImage,
    PdpSectionHeader,
    SelectItemCard,
    AffirmPackagePrice,
    FCButton,
    PackageStep,
    SizingStep,
    AffirmSection,
  },
  setup() {
    const activeStepIndex = ref(0);
    const scrollContainer = ref(null) as Ref<HTMLElement | null>;
    const isMobile = isMobileEffect();
    function validateStep() {
      activeStepIndex.value += 1;

      if (process.client && scrollContainer.value) {
        if (isMobile.value) {
        // only works for mobile
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 100);
        } else {
          // only works for desktop
          setTimeout(() => {
            scrollContainer.value.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    }

    const variantSelected = computed(() => $store.getters.variantSelected);
    async function addToCart() {
      if (!variantSelected.value) {
        return;
      }

      const { insertItemToCart } = useCartContext();
      await insertItemToCart(
        variantSelected.value.id,
        {
          type: 'equip',
        },
      );
      window.$nuxt.$router.push('/shop/checkout');
    }

    const { toggleDriftChat } = useDrift();
    onMounted(() => {
      toggleDriftChat(true);
    });

    onUnmounted(() => {
      toggleDriftChat(false);
    });

    // @ts-ignore
    const { $store } = useNuxtApp();
    const packageSelected = computed(() => $store.getters.packageSelected);
    const totalPrice = computed(() => packageSelected.value?.price);
    const getMainCtaPriceCopy = computed(() => (packageSelected.value?.oldPrice ? `<s>$${packageSelected.value.oldPrice / 100}</s>` : ''));
    const stepData = computed(() => (
      [
        {
          id: 'selectPackage',
          tabCopy: 'Package',
          header: 'Your FightCamp Package',
          description: 'FightCamp gets even better when more people in your household use it. Build the ideal package for your home.',
          component: {
            name: 'SelectItemCard',
            bindings: {},
          },
          stepComponent: {
            name: 'PackageStep',
          },
          mainCta: {
            copy: 'Next Step: Select Sizes',
            theme: 'button-tertiary',
            clickEvent: 'validateStep',
          },
        },
        {
          id: 'selectSize',
          tabCopy: 'Sizes',
          header: 'Choose your sizes',
          description: 'Use the guide above to choose the best fit.',
          component: {
            name: 'FCImage',
            bindings: {
              fcProvider: 'storyblok',
              nuxtImageProps: {
                src: 'https://a.storyblok.com/f/152150/1071x621/b871374b31/fc-sizing-chart-004.png',
                alt: 'size card',
              },
            },
          },
          stepComponent: {
            name: 'SizingStep',
          },
          mainCta: {
            copy: `Add to cart - $${totalPrice.value / 100} ${getMainCtaPriceCopy.value}`,
            theme: 'button-primary',
            clickEvent: 'addToCart',
          },
        }]
    ));

    // TODO not use this
    function handleTriggerClick(functionName: string) {
      this[functionName]();
    }

    return {
      validateStep,
      activeStepIndex,
      addToCart,
      media,
      packageSelected,
      stepData,
      handleTriggerClick,
      scrollContainer,
    };
  },
});
