import { render, staticRenderFns } from "./PackageBuilder.vue?vue&type=template&id=65e99540&scoped=true&"
import script from "./PackageBuilder.vue?vue&type=script&lang=ts&"
export * from "./PackageBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./PackageBuilder.vue?vue&type=style&index=0&id=65e99540&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e99540",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/opt/build/repo/apps/shop/components/storyblok/Video/Video.vue').default,SplitView: require('/opt/build/repo/apps/shop/components/storyblok/SplitView/SplitView.vue').default})
